<template>
<div class="scope">
    <div id="paymentSystem" class="paymentSystem">
        <div class="mb-2 topic text-center"><b>{{topic}}</b><br></div>
        <hr>
        <div v-html="detail"></div>
    </div>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
export default {
    mounted() {
        //document.title = 'คู่มือการใช้งาน'
        this.checkMode()
    },
    data() {
        return {
            topic: "คู่มือการใช้งาน",
            detail: `<div class="mb-2">ผู้ใช้ที่จะเข้าใช้บริการบนเว็บไซต์ www.novelrealm.com จะต้องจัดเตรียมอุปกรณ์ปลายทางที่เกี่ยวข้องกับให้บริการด้วยตนเอง (เช่น คอมพิวเตอร์ มือถือ อุปกรณ์เทคโนโลยีต่างๆที่สามารถเชื่อมต่อเข้ากับระบบอินเตอร์เน็ตได้ เป็นต้น)</div>
                    <div class="mb-2">ลูกค้าที่ลงทะเบียนเข้าใช้ระบบผ่านทางเว็บไซต์ www.novelrealm.com จะถือว่ารับทราบข้อนโยบายกำหนดการให้บริการของห้างหุ้นส่วนสามัญโนเวลเลียว์ม</div>
                    <div class="mb-2">
                        ขั้นตอนวิธีสมัครเพื่อเข้าใช้ระบบ <br>
                        1 ใส่ ชื่อ – นามสกุล<br>
                        2 ระบุ Email ที่ใช้งานจริง ( เพื่อทำการขอรหัสผ่านใหม่ในกรณีที่ลูกค้าลืมรหัสผ่าน )<br>
                        3.ใส่ชื่อที่แสดงในระบบ <br>
                        4. ตั้ง Username<br>
                        5. ตั้งรหัสผ่าน ( แนะนำให้ตั้งรหัสผ่าน 6 หลักขึ้นไป โดยผสมตัวอักษรและตัวเลข )<br>
                        6. กดช่องยืนยัน หลังจากกดรับทราบนโยบาย
                    </div>
                    <div class="mb-2">สำหรับลูกค้าที่สมัครผ่านทาง Facebook ทางเราจะได้รับข้อมูลเฉพาะส่วนที่ลูกค้าให้ไว้กับทาง Facebook </div>
                    หากมีข้อมูลไม่ครบถ้วน ให้ลูกค้าทำการแก้ไขข้อมูล กรอกรายละเอียดตามขั้นตอนด้านบนข้อที่ 1 – 5 ให้ครบ จากนั้นก็กดยืนยัน เพื่อทำการเปลี่ยนแปลง ( เมื่อผ่านขั้นตอนนี้ ผู้ใช้ที่ล็อคอินผ่าน Facebook จะสามารถเข้าใช้ระบบ โดยการล็อคอินปรกติได้เช่นเดียวกัน )`
        }
    },
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    methods: {
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('paymentSystem').classList.remove('darkmode')
        },
        darkmode() {
            document.getElementById('paymentSystem').classList.add('darkmode')
        }
    }
}
</script>

<style scoped>
.scope {
    padding: 3% 10% 3% 10%;
}

.paymentSystem {
    background-color: #ffffff;
    height: 57vh;
    overflow: scroll;
    border: 20px solid #ffffff;
}

.topic {
    font-size: 18px;
}

@media screen and (max-width: 500px) {
    .scope {
        padding: 2% 2% 2% 2%;
    }

    .paymentSystem {
        border: 10px solid #ffffff;
        font-size: 14px;
    }

    .topic {
        font-size: 13px;
    }
}

@media screen and (min-width: 1441px) {
    .scope {
        padding: 3% 20% 3% 20%;
    }
}

.darkmode {
    background-color: #35363A;
    border: 20px solid #35363A;
}
</style>
